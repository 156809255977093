import { apiClient } from "@/courses/services/ApiClientV2.js";
import { LEARNING_OBJECTIVES_API_V2_URLS } from "@/courses/urls.js";

export const getLearningObjectivesRequest = (courseId) => {
  return apiClient.get(
    LEARNING_OBJECTIVES_API_V2_URLS.index.stringify({ courseId }),
  );
};
export const getLearningObjectiveRequest = (courseId, learningObjectiveId) => {
  return apiClient.get(
    LEARNING_OBJECTIVES_API_V2_URLS.resource.stringify({
      courseId,
      learningObjectiveId,
    }),
  );
};
export const createLearningObjectiveRequest = async (courseId, data) => {
  const response = await apiClient.post(
    LEARNING_OBJECTIVES_API_V2_URLS.index.stringify({ courseId }),
    data,
  );
  return response;
};
export const updateLearningObjectiveRequest = async (
  courseId,
  learningObjectiveId,
  payload,
) => {
  const { data } = await apiClient.put(
    LEARNING_OBJECTIVES_API_V2_URLS.resource.stringify({
      courseId,
      learningObjectiveId,
    }),
    payload,
  );
  return data;
};
export const destroyLearningObjectiveRequest = (
  courseId,
  learningObjectiveId,
) => {
  const response = apiClient.delete(
    LEARNING_OBJECTIVES_API_V2_URLS.resource.stringify({
      courseId,
      learningObjectiveId,
    }),
  );
  return response;
};

export const getLearningObjectiveOptions = async (courseId) => {
  const url = LEARNING_OBJECTIVES_API_V2_URLS.options.stringify({
    courseId: courseId,
  });
  const { data } = await apiClient.get(url);
  return data;
};
