import _camelCase from "lodash/camelCase";
import _startCase from "lodash/startCase";
import _snakeCase from "lodash/snakeCase";
import { conditionType } from "@/courses/utilities/constants.js";

export const getConditionTypeValue = (conditionTypePath) => {
  // Gets condition type value from constants based on API path/metadata relationship key
  // assignment_types => assignmentType => AssignmentType
  // Used to pass expected value to DB for a condition_type
  return conditionType[getUnlockableTypeKey(conditionTypePath)];
};

export const getConditionTypeTermForString = (conditionType, currentCourse) => {
  // Returns the start case representation of the instructor-customized term for course content (Badge, Assignment, Assignment Type, etc)
  // Used to display the customized text values in unlock condition form
  let conditionTypeString = _snakeCase(conditionType.toLowerCase());
  if (currentCourse === null) {
    return conditionTypeString;
  }
  return _startCase(currentCourse.termFor[conditionType] || conditionType);
};

export const getUnlockableTypeKey = (unlockableTypePath) => {
  // Gets unlockable type constant key from route path
  // Used for converting API path/metadata relationship key to the key expected by `constants.conditionType`
  return _camelCase(unlockableTypePath.slice(0, unlockableTypePath.length - 1));
};

export const parseUnlocksErrors = (errorResponse) => {
  // Parses backend unlocks errors into human readable string
  return Object.keys(errorResponse).map((err) => {
    // { condition_id: ["can't be blank"] } => "condition_id can't be blank"
    return `${err} ${errorResponse[err].map((message) => `${message}`)}\n`;
  });
};
